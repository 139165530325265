import styled from "styled-components"
const Final = props => {
  const { formik } = props
  return(<>
    <Wrap>
      <Image>
        <img src="./img/penguin.png" alt="Penguin" />
      </Image>
      <Image>
        <img src="./img/grijalbo.png" alt="Grijalbo" />
      </Image>
      <div>
        <Button onClick={()=>formik.submitForm()}>
          Terminar
        </Button>
      </div>
    </Wrap>
  </>)
}
const Wrap = styled.div`
  display:grid ;
  grid-template-columns: repeat(3, 1fr);
  gap:20px;
  align-items:center ;
`
const Image = styled.div`
  img{
    display:block ;
    width:100% ;
  }
`
const Button = styled.button`
  width:100% ;
  border-radius: 10px ;
  border:none;
  padding:10px ;
  background-color:#2b0a01 ;
  color: #FFF;
`

export default Final