import { useContext } from "react"
import styled from "styled-components"
import MexicoTitle from '../layout/MexicoTitle'
import Image from '../layout/Image'
import { useFormik, FormikProvider } from "formik"
import * as Yup from "yup";
import { GlobalsContext } from '../../context/globals'
import Radio from "../layout/Radio"
import Reset from "../layout/Reset"
const SinRacismo = props => {
  const { state, dispatch } = useContext(GlobalsContext)
  const formik = useFormik({
    initialValues: {
      question: '',
    },
    onSubmit: values => {
      dispatch(
        {
          actualQuestion: 1,
          score: 0
        }
      )
    },
  });
  return(<>
    <FormikProvider value={formik}>
      <Wrap>
        <QuestionWrap>
          <div>
            <MexicoTitle />
            <QuestionNumer>Sin racismo</QuestionNumer>
            <QuestionTitle>
            <p>Si caes en esta calificación es importante tomar en cuenta lo siguiente; ya que está es una brecha peligrosa para poder caer en comportamientos o actitudes de devaluación que en su conjunto darían como resultado el llamado RACISMO. De igual forma podemos ser influenciados por la sociedad externa y poder caer fácilmente en lo anterior.</p>    
            <br></br>
            <p>Recomendaciones: </p>
            <br></br>
            <p>•	Trabajar en nuestro AMOR PROPIO desde una filosofía sana. </p>
            <br></br>
            <p>•	Seguir cuestionándonos las creencias de la sociedad y analizar si estás sumarían o restarían a nuestra vida, sin permitir que estos prejuicios y estereotipos se permeen en nuestra vida. </p>
            <br></br>
            <p>•	Continuar respetando las diferencias de los otros. </p>
            </QuestionTitle>
          </div>
          <Reset
            formik={formik} />
        </QuestionWrap>
        <div>
        <Image 
            url="./img/orgullo.png"
            title="orgullo" /> 
        </div>
      </Wrap>
    </FormikProvider>
  </>)
}
const Wrap = styled.div`
  width:100% ;
  height:100% ;
  display:grid ;
  grid-template-columns:repeat(2, 1fr) ;
  gap: 2%;
`
const QuestionWrap = styled.div`
  display:flex ;
  flex-direction: column ;
  justify-content:space-between ;
`
const QuestionNumer = styled.div`
  font-family:'Edo SZ';
  color: #2b0a01;
  font-size:4em ;
  margin-top:20px ;
`
const QuestionTitle = styled.div`
  font-size:16px ;
  margin-top:20px ;
`
export default SinRacismo