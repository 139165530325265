import { useContext } from 'react';
import './styles/globals.css'
import styled from 'styled-components'
import { GlobalsContext } from './context/globals';
import Welcome from './components/Welcome'
import Question1 from './components/questions/Question1';
import Question2 from './components/questions/Question2';
import Question3 from './components/questions/Question3';
import Question4 from './components/questions/Question4';
import Question5 from './components/questions/Question5';
import Question6 from './components/questions/Question6';
import Question7 from './components/questions/Question7';
import Question8 from './components/questions/Question8';
import Question9 from './components/questions/Question9';
import Question10 from './components/questions/Question10';
import Racismo from './components/questions/Racismo';
import Neutral from './components/questions/Neutral';
import SinRacismo from './components/questions/SinRacismo';
const App = () => {
  const { state, dispatch } = useContext(GlobalsContext)
  return (
    <Wrap>
      {console.log(typeof state.actualQuestion)}
      {state.actualQuestion === 'welcome' && 
        <Welcome />
      }
      {state.actualQuestion === 1 && 
        <Question1 />
      }
      {state.actualQuestion === 2 && 
        <Question2 />
      }
      {state.actualQuestion === 3 && 
        <Question3 />
      }
      {state.actualQuestion === 4 && 
        <Question4 />
      }
      {state.actualQuestion === 5 && 
        <Question5 />
      }
      {state.actualQuestion === 6 && 
        <Question6 />
      }
      {state.actualQuestion === 7 && 
        <Question7 />
      }
      {state.actualQuestion === 8 && 
        <Question8 />
      }
      {state.actualQuestion === 9 && 
        <Question9 />
      }
      {state.actualQuestion === 10 && 
        <Question10 />
      }
      {state.actualQuestion === 11 && state.score >= 24 && 
        <Racismo />
      }
      {state.actualQuestion === 11 && (state.score >= 16 && state.score <= 23) && 
        <Neutral />
      }
      {state.actualQuestion === 11 && state.score <= 15 && 
        <SinRacismo />
      }
    </Wrap>
  );
}
const Wrap = styled.section`
  width: 100% ;
  height:100vh ;
  background:url(./img/background.jpg) 50% 50% ;
  background-size: cover ;
  padding:5% ;
`
export default App;
