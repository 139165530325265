import styled from "styled-components"
const MexicoTitle = props => {
  const { url, title } = props
  return(<>
    <Wrap>
      <img src={url} alt={title} />
    </Wrap>
  </>)
}
const Wrap = styled.div`
  width:100% ;
  height:100% ;
  padding:2em ;
  display: flex ;
  img{
    display:block ;
    width:100% ;
    margin:auto ;
    max-height:600px ;
  }

`
export default MexicoTitle