import React from "react";
import { Field } from "formik";
import styled from "styled-components";
const Radio = (props) => {
  const { 
    name, 
    label, 
    disabled,
    itemValue,
  } = props;
  return (
    <Field type="radio" name={name} value={itemValue} id={label}>
      {({ field, form: { touched, errors }, meta }) => (
        <InputWrap>
          <MyStyledInput
            name={name}
            type="radio"
            value={itemValue}
            id={label}
            {...field}
          />
          <label 
            className={meta.touched && meta.error ? "error" : ""}
            disabled={disabled}
            htmlFor={label}>
              {label}
              
            </label>
        </InputWrap>
      )}
    </Field>
  );
};
const InputWrap = styled.div`
  width: 100%;
  margin:1em 0 ;
  label{
    display:flex ;
    width:100% ;
    justify-content:space-between ;
    align-items:center ;
    padding:15px ;
    border:1px solid black;
    border-radius: 5px;
    font-weight:500;
    font-size:12px ;
    cursor: pointer;
    &.error {
      border:1px solid red;
    }
  }
  input[type="radio"]{}
  input[type="radio"]:checked + label{
    border:1px solid red;
  }
`;

const MyStyledInput = styled.input`
  display: none ;
`;
export default Radio;
