import styled from "styled-components"
import { GlobalsContext } from "../../context/globals"
import { useContext } from "react"
const Buttons = props => {
  const { state, dispatch } = useContext(GlobalsContext)
  const { formik } = props
  return(<>
    <div>
      <WrapButtons>
        {state.actualQuestion > 1 &&
          <Button onClick={()=>dispatch({actualQuestion: state.actualQuestion - 1})}>
            Anterior
          </Button>
        }
        <Button onClick={()=>formik.submitForm()}>
          Siguiente
        </Button>
      </WrapButtons>
      <Wrap>
        <Image>
          <img src="./img/penguin.png" alt="Penguin" />
        </Image>
        <Image>
          <img src="./img/grijalbo.png" alt="Grijalbo" />
        </Image>
      </Wrap>
    </div>
  </>)
}
const Wrap = styled.div`
  display: flex ;
  justify-content:center ;
  margin-top:30px ;
`
const WrapButtons = styled.div`
  display:flex ;
  justify-content:center ;
  gap:30px;
  margin-top:30px ;
`
const Image = styled.div`
  img{
    display:block ;
    width:80% ;
    max-width:200px ;
  }
`
const Button = styled.button`
  width:80% ;
  border-radius: 10px ;
  border:none;
  padding:10px ;
  background-color:#2b0a01 ;
  color: #FFF;
  cursor: pointer;
`

export default Buttons