import { useContext } from "react"
import styled from "styled-components"
import MexicoTitle from '../layout/MexicoTitle'
import Image from '../layout/Image'
import { useFormik, FormikProvider } from "formik"
import * as Yup from "yup";
import { GlobalsContext } from '../../context/globals'
import Radio from "../layout/Radio"
import Buttons from "../layout/Buttons"
const Question7 = props => {
  const { state, dispatch } = useContext(GlobalsContext)
  const formik = useFormik({
    initialValues: {
      question: '',
    },
    validationSchema: Yup.object({
      question: Yup.string()
        .required('Este campo es requerido'),
    }),
    onSubmit: values => {
      dispatch(
        {
          actualQuestion: state.actualQuestion + 1,
          score: state.score + Number(values.question)
        }
      )
    },
  });
  return(<>
    <FormikProvider value={formik}>
      <Wrap>
        <QuestionWrap>
          <div>
            <MexicoTitle />
            <QuestionNumer>07</QuestionNumer>
            <QuestionTitle>
            ¿Qué papel actoral le darías a esta concursante para el casting de una película?
            </QuestionTitle>
            <QuestionsWrap>
              <Radio
                label="Protagonista"
                name="question"
                itemValue="1"/>
              <Radio
                label="Secundaria"
                name="question"
                itemValue="2"/>   
              <Radio
                label="Extra"
                name="question"
                itemValue="3"/>            
            </QuestionsWrap>
          </div>
          
          <Buttons
            formik={formik} />
          
        </QuestionWrap>
        <div>
        <Image 
            url="./img/7.png"
            title="6" 
            style="width:500px"/>
        </div>
      </Wrap>
    </FormikProvider>
  </>)
}
const Wrap = styled.div`
  width:100% ;
  height:100% ;
  display:grid ;
  grid-template-columns:repeat(2, 1fr) ;
  gap: 2%;
`
const QuestionWrap = styled.div`
  display:flex ;
  flex-direction: column ;
  justify-content:space-between ;
`
const QuestionNumer = styled.div`
  font-family:'Edo SZ';
  color: #2b0a01;
  font-size:4em ;
  margin-top:20px ;
`
const QuestionTitle = styled.div`
  font-size:2em ;
  margin-top:20px ;
`
const QuestionsWrap = styled.div`
  font-size:2em ;
  margin-top:20px ;
`
export default Question7