import styled from "styled-components"
const MexicoTitle = props => {
  return(<>
    <Wrap>
      <img src="./img/mexico-title.png" alt="México Racista" />
    </Wrap>
  </>)
}
const Wrap = styled.div`


`
export default MexicoTitle