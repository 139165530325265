import { createContext, useReducer } from "react";

var initialState = {
  actualQuestion: 'welcome',
  score:0,
};
function reducer(state, action) {
  return { ...state, ...action };
}
const GlobalsContext = createContext();
const GlobalsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalsContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalsContext.Provider>
  );
};

export { GlobalsContext, GlobalsProvider };
