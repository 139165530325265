import styled from 'styled-components'
import { useContext } from 'react'
import { GlobalsContext } from '../context/globals'
const Welcome = props => {
  const { dispatch } = useContext(GlobalsContext)
  
  return(
    <Wrap>
      <div className="center">
        <h1>Test racismo</h1>
        <p> ¿Discriminas? Realiza el test y únete al movimiento por la Cero discriminación. El objetivo del test es identificar si has tenido alguna actitud o comportamiento racista reciente que pudiera tener consecuencias en tu entorno directo e indirecto a nivel general.  </p>
        <h4>¿Cómo funciona?</h4>
        <p>Responde a 10 preguntas y de acuerdo con tus respuestas, al finalizar el test podrás saber si existe en ti discriminación racial.</p>
        <Button onClick={()=>dispatch({actualQuestion:Number(1)})}>
          Comenzar
        </Button>
        <Logos>
          <Image>
            <img src="./img/penguin.png" alt="Penguin" />
          </Image>
          <Image>
            <img src="./img/grijalbo.png" alt="Grijalbo" />
          </Image>
        </Logos>
      </div>
    </Wrap>
  )
}
const Wrap = styled.section`
width:100% ;
height:100% ;
display:flex ;
justify-content:center ;
align-items:center ;
  .center{
    text-align:center ;
    width:70% ;
    margin:auto ;
  }
  h1, h4{
    font-size:2.5em ;
  }
  p{
    font-size:2em ;
    margin-bottom:1em ;
  }
`
const Button = styled.button`
  width:80% ;
  border-radius: 10px ;
  border:none;
  padding:10px ;
  background-color:#2b0a01 ;
  color: #FFF;
  cursor: pointer;
`
const Logos = styled.div`
  margin-top:100px ;
  display:flex ;
  justify-content:space-between ;
`
const Image = styled.div`
  img{
    display:block ;
    width:80% ;
    max-width:200px ;
  }
`
export default Welcome