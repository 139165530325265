import { useContext } from "react"
import styled from "styled-components"
import MexicoTitle from '../layout/MexicoTitle'
import Image from '../layout/Image'
import { useFormik, FormikProvider } from "formik"
import * as Yup from "yup";
import { GlobalsContext } from '../../context/globals'
import Radio from "../layout/Radio"
import Reset from "../layout/Reset"
const Racismo = props => {
  const { state, dispatch } = useContext(GlobalsContext)
  const formik = useFormik({
    initialValues: {
      question: '',
    },
    onSubmit: values => {
      dispatch(
        {
          actualQuestion: 1,
          score: 0
        }
      )
    },
  });
  return(<>
    <FormikProvider value={formik}>
      <Wrap>
        <QuestionWrap>
          <div>
            <MexicoTitle />
            <QuestionNumer>Con racismo</QuestionNumer>
            <QuestionTitle>
              <p>El racismo es una ideología que defiende la superioridad de una raza frente a las demás. Lo anterior referente a la necesidad de mantenerla aislada o separada del resto dentro de una comunidad o un país. </p>
              <br></br>
              <p>Recomendaciones:</p>
              <br></br>
              <p>•	Empatizar con el otro. </p>
              <br></br>
              <p>•	Entender al otro como ser humano con sus propias batallas y miedos. </p>
              <br></br>
              <p>•	Desmaterializar al ser humano ya que todos somos ESPÍRITU, con características individuales, físicas, económicas y emocionales diferentes. </p>
              <br></br>
              <p>•	El VALOR de una persona no va aunado a sus rasgos individuales, sociales, físicos, económicas y emocionales. </p>
            </QuestionTitle>
          </div>
          <Reset
            formik={formik} />
        </QuestionWrap>
        <div>
        <Image 
            url="./img/orgullo.png"
            title="orgullo" /> 
        </div>
      </Wrap>
    </FormikProvider>
  </>)
}
const Wrap = styled.div`
  width:100% ;
  height:100% ;
  display:grid ;
  grid-template-columns:repeat(2, 1fr) ;
  gap: 2%;
`
const QuestionWrap = styled.div`
  display:flex ;
  flex-direction: column ;
  justify-content:space-between ;
`
const QuestionNumer = styled.div`
  font-family:'Edo SZ';
  color: #2b0a01;
  font-size:4em ;
  margin-top:20px ;
`
const QuestionTitle = styled.div`
  font-size:16px ;
  margin-top:20px ;
`
export default Racismo